import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col, Fade, Checkbox } from "react-bootstrap";
import { navigate } from "@reach/router";
import PropertyCard from "../PropertyCard/PropertyCard"
import Dummy from "../../../images/job-no-image.jpg"
import { useWindowSize } from '../../../hooks/winodw-size'
import Select from 'react-select';
import parse from "html-react-parser";
import _, { set } from 'lodash'
import GenerateLink from "../../common/site/generate-link"

import "./SearchList.scss";
const SearchList = (props) => {
  const [windowWidth] = useWindowSize();

  // const [jobs, setJobs] = useState([])
  var intialNumberOfReview = 8;
  const [brandselectedOption, setBrandSelectedOption] = useState({ value: 'all_brands', label: 'All Brands' })
  const [locationselectedOption, setLocationSelectedOption] = useState({ value: 'all_locations', label: 'All Locations' })
  const [jobTypeSelectedOption, setJobTypeSelectedOption] = useState({ value: 'all_job_types', label: 'All Job Types' })
  const [search, setSearch] = useState(null)
  const [checked, setChecked] = useState(false)
  const [isLess, setLess] = useState(false);
  const [loadMoreList, setLoadMoreList] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  var brand_options = [{ value: 'all_brands', label: 'All Brands' },{ value: 'wk', label: 'Wards' },{ value: 'cw', label: 'Cubitt & West' },{ value: 'da', label: 'Douglas Allen' },{ value: 'pt', label: 'Pittis' },{ value: 'wk', label: 'Wards' }];
  var location_options = [{ value: 'all_locations', label: 'All Locations' }];
  var job_type_options = [{ value: 'all_job_types', label: 'All Job Types' }];

  const bandages = props.adverts

  _.forEach(props.jobs, (branch) => {
    var brand_name = branch.node.brand_id
    // if(branch.brand_id == "wk") {
    //   brand_name = "Wards"
    // }
    // if(branch.brand_id == "cw") {
    //   brand_name = "Cubitt & West"
    // }
    // if(branch.brand_id == "pt") {
    //   brand_name = "Pittis"
    // }
    // if(branch.brand_id == "da") {
    //   brand_name = "Douglas Allen"
    // }
    if(branch.node.brand_id == "mmd") {
      brand_name = "Mortgage Matters Direct"
    }
    if(branch.node.brand_id == "ae") {
      brand_name = "Arun Estates"
    }
    if(branch.node.brand_id == "fc") {
      brand_name = "Fine & Country"
    }
    brand_options.push({ value: branch.node.brand_id, label: brand_name })

  })
  brand_options = _.uniqBy(brand_options, (e) => e.value);
  brand_options = _.orderBy(brand_options, [e => e.value.toLowerCase()], ['asc']);

  var brandhandleChange = brand_options => {
    setBrandSelectedOption(brand_options);
  };

  _.forEach(props.jobs, (job) => {
    if(job.node.brand_id == brandselectedOption.value) {
      location_options.push({ value: job.node.vacancy_location, label: job.node.vacancy_location })
    } else if(brandselectedOption.value == 'all_brands') {
      location_options.push({ value: job.node.vacancy_location, label: job.node.vacancy_location })
    }

  })

  _.forEach(props.branches, (node) => {
      location_options.push({ value: node.branch_name, label: node.branch_name })
  })
  
  location_options = _.uniqBy(location_options, (e) => e.value);
  location_options = _.orderBy(location_options, [e => e.value.toLowerCase()], ['asc']);
  var locationhandleChange = locationselectedOption => {
    setLocationSelectedOption(locationselectedOption);
  };

  _.forEach(props.jobs, (job) => {
    job_type_options.push({ value: job.node.vacancy_type, label: job.node.vacancy_type })
  })
  job_type_options = _.uniqBy(job_type_options, (e) => e.value);
  var jobtypehandleChange = jobtypeselectedOption => {
    setJobTypeSelectedOption(jobtypeselectedOption);
  };

  useEffect(() => {
    // setLoadMoreList(jobs)
    var navigate_url = '/vacancy-type/';
    var jobs = _.clone(props.jobs);
    if (brandselectedOption.value !== "all_brands") {
      jobs = jobs.filter(job => {
        return brandselectedOption.value == job.node.brand_id
      });
      navigate_url = `/vacancy-type/estate-agent-jobs-in-${brandselectedOption.value.replace(/ /g, "-").toLowerCase()}`;
    }
    if (locationselectedOption.value !== "all_locations") {
      jobs = jobs.filter(job => {
        return locationselectedOption.value == job.node.vacancy_location
      });
      navigate_url = `/vacancy-type/estate-agent-jobs-in-${locationselectedOption.value.replace(/ /g, "-").toLowerCase()}`;
    }
    if (jobTypeSelectedOption.value !== "all_job_types") {
      jobs = jobs.filter(job => {
        return jobTypeSelectedOption.value == job.node.vacancy_type
      });
      navigate_url = `/vacancy-type/${jobTypeSelectedOption.value.replace(/ /g, "-").toLowerCase()}-jobs/`;
    }
    if (search) {
      const escapeRegExp = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      jobs = jobs.filter(value => {
        return new RegExp(`\\b${escapeRegExp(search)}`, "i").test(value.node.vacancy_title);
      });
      navigate_url = `/vacancy-type/estate-agent-jobs-in-${_.trim(search).replace(/ /g, "-").toLowerCase()}`;
    }

    if(checked) {
      jobs = jobs.filter(job => {
        return job.node.vacancy_full_time == !checked
      });
    }
    else {
      jobs = jobs
    }

    if (search && locationselectedOption.value !== "all_locations") {
      navigate_url = `/vacancy-type/estate-agent-jobs-in-${_.trim(search).replace(/ /g, "-").toLowerCase()}-${locationselectedOption.value.replace(/ /g, "-").toLowerCase()}`;
    }

    if (search && jobTypeSelectedOption.value !== "all_job_types") {
      navigate_url = `/vacancy-type/${_.trim(jobTypeSelectedOption.value)}-jobs-in-${_.trim(search).replace(/ /g, "-").toLowerCase()}/`;
    }
    if (locationselectedOption.value !== "all_locations" && jobTypeSelectedOption.value !== "all_job_types") {
      navigate_url = `/vacancy-type/${_.trim(jobTypeSelectedOption.value)}-jobs-in-${_.trim(locationselectedOption.value).replace(/ /g, "-").toLowerCase()}/`;
    }
    if (search && jobTypeSelectedOption.value !== "all_job_types" && locationselectedOption.value !== "all_locations") {
      navigate_url = `/vacancy-type/estate-agent-jobs-in-${_.trim(search).replace(/ /g, "-").toLowerCase()}-${_.trim(locationselectedOption.value).replace(/ /g, "-").toLowerCase()}/${_.trim(jobTypeSelectedOption.value).replace(/ /g, "-").toLowerCase()}`;
    }

    // navigate(navigate_url);

    setFilteredJobs(jobs)
    setLess(false);
    setLoadMoreList(jobs.slice(0, 8))



  }, [brandselectedOption, locationselectedOption, jobTypeSelectedOption, search, checked])

  const loadMore = () => {
    let primeryLength = loadMoreList.length;
    let newList = filteredJobs.slice(
      0,
      primeryLength + intialNumberOfReview
    );
    setLoadMoreList(newList);
    if (newList.length === filteredJobs.length) {
      setLess(true);
    } else {
      setLess(false);
    }
  };

  const styles = {
    control: styles => ({ ...styles, color: '#4D676F', minHeight: '3rem', paddingLeft: '1rem', marginBottom: '1rem' }),
    singleValue: styles => ({ ...styles, paddingBottom: '1px' }),
    
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#4D676F',
      }
    }
  }


  const branchImage = (id) => {
    var image = Dummy;
    if (!_.isEmpty(id)) {
      var branch = _.find(props.branches, { branch_id: id });
      if (!_.isEmpty(branch)) {
        if (branch.image.length > 0) {
          image = branch.image[0].url;
        }
      }
    }
    return image;
  }

  const add3Dots = (string, limit) => {
    var dots = "...";
    if (string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }

    return string;
  }



  return (
    <React.Fragment>
      <div className="search-list-block">

        <Container>
          <Row>
            <Col md="12">
              <div className="property-view">
                <div className="select-box">
                  <Select
                    value={brandselectedOption}
                    onChange={brandhandleChange}
                    options={brand_options}
                    //defaultMenuIsOpen={false}
                    className={`select-form-control`}
                    classNamePrefix={`reactSelect2`}
                    styles={styles}
                  />
                </div>
                <div className="select-box">
                  <Select
                    value={locationselectedOption}
                    onChange={locationhandleChange}
                    options={location_options}
                    //defaultMenuIsOpen={false}
                    className={`select-form-control`}
                    classNamePrefix={`reactSelect2`}
                    styles={styles}
                  />
                </div>
                <div className="select-box">
                  <Select
                    value={jobTypeSelectedOption}
                    onChange={jobtypehandleChange}
                    options={job_type_options}
                    //defaultMenuIsOpen={false}
                    className={`select-form-control`}
                    classNamePrefix={`reactSelect2`}
                    styles={styles}
                  />
                </div>
                {/* <span className="search-number">1032 Properties for Sale in Kent  <i className="icon-info"></i></span> */}
                <div className="search-right">
                  <label className="custom-checkbox  d-flex align-items-center me-3">Part Time Only
                    <input type="checkbox" onChange={(e) => { setChecked(e.target.checked) }} />
                    <span className="checkmark"></span>
                  </label>


                  <div className="search-box">
                    <div className="search-wrap">
                      <Form.Control
                        type="search"
                        placeholder="Search jobs"
                        onChange={(e) => { setSearch(e.target.value) }}
                      />
                      <i className="icon-input-search"></i>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {/* {isMobile && 
      <div>text</div>
      } */}

            {loadMoreList && loadMoreList.map((job, i) => {
              var random = Math.floor(Math.random() * bandages.length);
              return (
                <Col md="12" className="col-hide" key={i}>
                  {i === 0 &&
                    <div className="top-bar">
                      <span className="top-bar-text">Featured vacancy</span>
                    </div>
                  }
                  <div className="property-card property-card-big property-card-top">
                    <div className="properties-wrapper">
                      <Link to={`/vacancy/${job.node.vacancy_id}/`}>
                        <picture className="img-zoom">
                          <img src={branchImage(job.node.branch_id)} alt="img" />
                        </picture>
                      </Link>
                    </div>
                    <div className="about-properties">
                      <div className="properties-info">
                        <h2>
                          <Link to={`/vacancy/${job.node.vacancy_id}/`}> {job.node.vacancy_title}</Link>
                        </h2>
                        <div className="properties-icons">
                          <span className="text">
                            <i className="icon-map-pin-light"></i>
                            <span className="property-price">{job.node.vacancy_location}</span>
                          </span>
                        </div>
                        {job.node.vacancy_salary_text && <div className="properties-icons">
                          <span className="text">
                            <i className="icon-currency"></i>
                            <span className="property-price">{job.node.vacancy_salary_text.replace("£Depending", "Depending")}</span>
                          </span>
                        </div> }
                        <p>{parse(add3Dots(job.node.vacancy_short_description, 400) )}</p>

                      </div>
                    </div>
                  </div>
                  {(i + 1) % 3 == 0 &&
                    <section className="book-valuation components--Home--BookValuation--BookValuation">
                      <Container>
                        <Row className="main-row">
                          <Col lg="12">
                            <div className="register-interest-form-block d-flex justify-content-between">

                              <div className="valuation-block">
                                <div class="set-size">
                                  <div class="pie-wrapper progress-90">
                                    {bandages[random].Value &&
                                      <span class="label">{bandages[random].Value}</span>
                                    }
                                    <div class="pie">
                                      <div class="left-side half-circle"></div>
                                      <div class="right-side half-circle"></div>
                                    </div>
                                  </div>


                                </div>
                                <h2>{bandages[random].Title}</h2>
                                {parse(bandages[random].Content)}
                                {/* <p>Let our staff explain why they choose to continue their career with us.</p> */}
                              </div>
                              <div className="btn-row">
                                <GenerateLink link={bandages[random].CTA_Link}  class="btn btn-secondary">{bandages[random].CTA_Label} <i class="icon-arrow"></i></GenerateLink>
                                {/* <Link class="btn btn-secondary" href="/vacancy-type">Find out more<i class="icon-arrow"></i></Link> */}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>
                  }
                </Col>
              )
            })}
            <Col md="12">
              <div className="property-card-list d-lg-none">
                {loadMoreList && loadMoreList.map((item, index) => <PropertyCard job={item.node} branches={props.branches} />
                )}

              </div>
            </Col>
            {loadMoreList.length === 0 &&
              <Col md="12">
                <div className="no-result">
                  Sorry, No results found
                </div>
              </Col>
            }

            <Col md="12">
              <div className="d-flex justify-content-center load-more-btn learn-more">
                {filteredJobs && filteredJobs.length > 8 && !isLess ? (
                  <Button variant="outline" className="btn btn-secondary " onClick={loadMore}>
                    Load More
                  </Button>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>

      </div>
    </React.Fragment>
  );
};

export default SearchList;
